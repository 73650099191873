.contentList, .contentFilter, .contentDetail, .contentHeader, .contentListCompanies {
  margin:20px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 10px 0 rgba(0,0,0,0.1);
  min-width: 990px;
  position: relative;
}

.contentFilter {
  background-color: white;
  padding: 10px;
  position:relative;
}

.FilterSearchBlockField {
  margin-bottom: 10px;
}

.FilterSearchBlockField .SearchBoxText .iconeLeft {
  top: 39px;
  position: absolute;
}

.resetButtonFilter {
  position:absolute;
  top:12px;
  right:15px;
  color: #94979B;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
.resetButtonFilter i {
  font-size: 20px;
}

.resetButtonFilter span {
  margin-left:5px;
  position: relative;
  top:-3px;
}

.resetButtonFilter:hover{
  cursor: pointer;
}

.resetButtonFilter.hide {
  display:none;
}

.resetButtonFilterCompanies {
  position:absolute;
  top:2px;
  right:36px;
  color: #94979B;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
.resetButtonFilterCompanies i {
  font-size: 20px;
}

.resetButtonFilterCompanies span {
  margin-left:5px;
  position: relative;
  top:-3px;
}

.resetButtonFilterCompanies:hover{
  cursor: pointer;
}

.resetButtonFilterCompanies.hide {
  display:none;
}

#input-search-with-sx-label.MuiInputLabel-shrink,
#category-simple-select-standard-label.MuiInputLabel-shrink,
#filterCheckBox-multiple-checkbox-label.MuiInputLabel-shrink
{
  color: #1976d2;
}

#filterCheckBox-multiple-checkbox {
  padding:10px 25px 10px 10px;
}
#filterCheckBox-multiple-checkbox-label {
  top:-5px;
  padding-right: 20px;
}

#filterCheckBox-multiple-checkbox-label.MuiInputLabel-shrink {
  top:0px;
}

.filterCheckBox {
  margin-right:10px!important;
}

.FilterStatusContent {
  border-left:1px solid #CCC;
  margin-left:0px;
  padding-left:8px;
}

.statusFilter {
  display: inline-block;
  height:25px;
  width:25px;
  position:relative;
  text-align:center;
  vertical-align:middle;
  line-height:24px;
  border-radius: 25px;
  margin-left:4px;
  font-size: 14px;
  background-color: white;
  border:1px solid #CCC;
  color:#CCC;
}

.statusFilterbackground {
  position:fixed;
  top:-1px;
  left:-5px;
  height:25px;
  width:25px;
  position:absolute;
  text-align:center;
  vertical-align:middle;
  line-height:25px;
  border-radius: 25px;
  margin-left:4px;
  font-size: 14px;
  background-color: none;
  z-index:1;
}
.statusFilterbackground.hide {
  background-color:white!important;
  border:1px solid #CCC;
  line-height:24px;
}

.statusFilter:hover {
  cursor: pointer;
  border:1px solid white;
  color:black;
}

.statusFilter.filterActived {
  border:1px solid white;
  color:black;
}

.contentList {
  background-color: white;
  height: calc(100% - 195px);
}

.contentListCompanies {
  background-color: white;
  height: calc(100% - 150px);
}

.MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-columnHeader:focus {
  outline:none!important;
}

.MuiDataGrid-columnHeaders {
  background-color: #F8F8F8;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.06);
}

.MuiDataGrid-columnHeaderTitle {
  color: #94979B;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.MuiDataGrid-footerContainer,
.MuiTablePagination-root,
.MuiToolbar-root {
  color: #94979B;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  top:7px;
  position: relative;
}

.MuiDataGrid-cellContent {
  color: #000000;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.MuiDataGrid-columnHeadersInner {
  padding-top:4px;
}

.MuiDataGrid-row:hover {
  cursor:pointer;
}
.dataGridRowMissionCanceled {
  background-color: rgba(255, 0, 0, 0.05);
}
.dataGridRowMissionCanceled:hover {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.MuiDataGrid-row:first {
  padding:0px;
}

.typeMissionGridColor {
  display: block;
  width:2px;
  height:70%;
  padding-right:10px;
  left:-3px;
  position: relative;
}

.statusMissionGridColor {
  display: block;
  width:30px;
  height:30px;
  position:relative;
  text-align:center;
  vertical-align:middle;
  line-height:30px;
  border-radius: 30px;
}

#listExport {
  position:absolute;
  bottom:12px;
  left:12px;
}

#listExport .export.hide {
  display:none;
}

button.small {
  font-size: 11px;
  border-radius: 10px;
}
button.small:has(.MuiButton-startIcon) {
  padding: 1px 6px 0px 4px !important;
}
button.small:has(.MuiButton-endIcon) {
  padding: 1px 4px 0px 6px !important;
}
button.small:not(.link):hover {
  color: white;
  background-color: #1976d2;
}
button.small svg {
  font-size: 12px;
  margin: -2px 2px 0px 0px;
}
button.small .MuiButton-endIcon {
  margin-left: 3px !important;
}
button.small .MuiButton-startIcon {
  margin-right: 3px !important;
}

button.small.link {
  border: none;
  padding: 0px !important;
}
button.small.link:hover {
  background-color: transparent;
}
