.blocReceiverDiv .receiverDiv,
.blocReceiverDiv .blocReceiverLotDiv .receiverLotDiv {
  border-radius: 0px !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom: none !important;
  /*box-shadow: none !important;*/
  margin: 0px 0px 0px 8px !important;
  /*box-shadow: 0px 2px 0px -1px rgba(0,0,0,0.2),0px 1px 0px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);*/
}

.blocReceiverDiv .blocReceiverLotDiv .receiverLotDiv {
  margin: 0px 8px 0px 8px !important;
}

.blocReceiverDiv .receiverDiv:first-child,
.blocReceiverDiv .blocReceiverLotDiv .receiverLotDiv:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.blocReceiverDiv .receiverDiv:last-child,
.blocReceiverDiv .blocReceiverLotDiv .receiverLotDiv:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.receiverAccordion,
.receiverLotAccordion {
  box-shadow: none !important;
  border: none !important;
}

.receiverAccordionSummary div,
.receiverLotAccordionSummary div {
  margin: 0px !important;
}

.receiverAccordionSummary .receiverNameDiv,
.receiverLotAccordionSummary .receiverLotNumberDiv {
  top: 8px;
  position: relative;
}

.receiverIconSpacer {
  height: 40px;
  padding: 8px 0px;
}

.receiverFieldsDiv {
  margin-bottom: 12px;
}

.addLotButton {
  height: 40px;
}

.divLot {
  margin: 30px 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 6px;
  padding: 10px;
}

.receiverLotDiv .lineContent .fieldContent {
  width: 18%;
  margin: 10px 8px 0px 8px;
}

.receiverLotDiv .lineContent .datagridBL, .receiverLotDiv .lineContent .datagridCompanies {
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin: 10px 8px 0px 8px;
}
.receiverLotDiv .lineContent .datagridCompanies {
  min-width: 265px;
}
.receiverLotDiv .lineContent .datagridCompanies .addButtonSelect2 {
  top: -10px;
  right: -5px;
}

.receiverLotDiv .lineContent .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding-bottom: 8px !important;
}

.receiverLotDiv div.lineContent.noJustify > div:last-child{
  margin-right: 8px !important;
}

.exportCRDiv .lineContent {
  width: 100% !important;
  margin-left: 0px !important;
}

.exportCRDiv .lineContent .MuiFormControl-root {
  margin: 0px !important;
}
