.slideCollapse {
    top: 0px;
    left: 200px;
    z-index: 900;
    position: absolute;
    background-color: white;
    height: 100% !important;
    width: calc(75% - 200px);
    box-shadow: 1px 0 10px 0 rgba(0,0,0,0.1);
}
.slideCollapse.big {
    width: calc(87% - 200px);
}

.slideCompanyContact {
    position: relative;
}

.slideHeader {
    color: #014388;
    font-size: 62px;
    font-weight: bold;
    padding: 40px 7.8%;
}
.slideCollapse.big .slideHeader {
    padding-left: 6.6%;
}

.slideHeader .slideClose {
    top: 35px;
    right: 55px;
    position: absolute;
}

.slideContent {
    height: calc(100vh - 120px);
    padding: 28px 0px 20px 8.08%;
}
.slideCollapse.big .slideContent {
    padding-left: 6.8%;
}

.slideContent .block {
    float: left;
    width: auto;
    height: calc(100% - 40px);
    display: block;
}

.slideContent .block.blockZone {
    width: 14.2%;
}
.slideCollapse.big .slideContent .block.blockZone {
    /*width: 11.8%;*/
    width: 13.8%;
}
.slideContent .block.blockCountry {
    width: 51.6%;
    padding-left: 5.4%;
}
.slideCollapse.big .slideContent .block.blockCountry {
    /*width: 42.9%;
    padding-left: 4.5%;*/
    width: 50.9%;
    padding-left: 6.5%;
}
.slideContent .block.blockContact {
    width: 34.2%;
}
.slideCollapse.big .slideContent .block.blockContact {
    /*width: 45.3%;*/
    width: 35.3%;
}

.slideContent .block .blockTitle {
    font-size: 26px;
    color: #014388;
    font-weight: bold;
    padding-bottom: 15px;
}
.slideContent .block.blockContact .blockTitle {
    padding-left: 14.1%;
}
.slideCollapse.big .slideContent .block.blockContact .blockTitle {
    /*padding-left: 9%;*/
    padding-left: 18%;
}

.slideContent .block .blockContent {
    height: calc(100% - 55px);
}
.slideContent .block.blockZone .blockContent {
    border-right: 1px solid #94979b;
}
.slideContent .block.blockContact .blockContent {
    overflow: auto;
    position: relative;
    border-left: 1px solid #94979b;
}
.slideContent .block.blockCountry .blockContent {
    overflow: auto;
}

.slideContent .block .blockContent ul { padding: 0px; }
.slideContent .block .blockContent ul.blockList {
    float: left;
    width: 32.5%;
    display: block;
}
.slideContent .block.blockContact .blockContent ul.blockList {
    width: 100%;
    display: block;
    padding-left: 13.8%;
}
.slideCollapse.big .slideContent .block.blockContact .blockContent ul.blockList {
    /*width: 50%;
    padding-left: 9%;*/
    width: 98%;
    padding-left: 18.5%;
}

.slideContent .block .blockContent li {
    margin-bottom: 6px;
    list-style-type: none;
}
.slideContent .block.blockCountry li {
    margin-right: 4.5%;
}
.slideContent .block .blockContent li span, .slideContent .block.blockContact .blockContent li {
    font-size: 14px;
    cursor: pointer;
    color: #94979b;
    font-weight: 600;
    text-transform: capitalize;
}
.slideContent .block .blockContent li span:hover, .slideContent .block .blockContent li span.active, .slideContent .block.blockContact .blockContent li.contact_name {
    color: black;
}
.slideContent .block .blockContent li span.txt, .slideContent .block.blockContact .blockContent li {
    cursor: default;
}

.slideContent .block.blockContact .blockContent li {
    margin: 0px;
    margin-bottom: 3px;
}
.slideContent .block.blockContact .blockContent li.company_name {
    color: black;
    font-size: 19px;
    text-transform: uppercase;
}
.slideContent .block.blockContact .blockContent li.company_co_name {
    color: #434549;    
    font-size: 16px;
    text-transform: uppercase;
    margin-top: -9px;
    margin-bottom: 15px;
}
.slideContent .block.blockContact .blockContent li.company_address_comp {
    padding-left: 25px;
}
.slideContent .block.blockContact .blockContent li.company_email, .slideContent .block.blockContact .blockContent li.contact_email {
    text-transform: lowercase;
}

.slideContent .block.blockContact .blockContent li a, .slideContent .block.blockContact .blockContent li a:visited {
    color: #94979b;
    text-decoration: none;
}
.slideContent .block.blockContact .blockContent li a:hover, .slideContent .block.blockContact .blockContent li a:active {
    color: black;
}

.slideContent .block.blockContact .blockContent li .icon {
    margin-right: 5px;
}
.slideContent .block.blockContact .blockContent li.company_email .icon, .slideContent .block.blockContact .blockContent li.contact_email .icon, .slideContent .block.blockContact .blockContent li.contact_name .icon {
    margin-top: -2px;
}

.slideContent .block.blockContact .blockContent .blockPsMarge {
    margin-top: 85px;
}
.slideContent .block.blockContact .blockContent .blockPs {
    right: calc(25% + 15px);
    bottom: 0px;
    position: fixed;

    /*width: 260px;*/
    width:21%;
    color: black;
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    /*padding: 0px 20px 27px 20px;*/
    padding: 20px 0px 20px 0px;
}
.slideCollapse.big .slideContent .block.blockContact .blockContent .blockPs {
    right: calc(13% + 15px);
}
.slideContent .block.blockContact .blockContent .blockPs a, .slideContent .block.blockContact .blockContent .blockPs a:visited {
    color: black;
    text-decoration: none;
}
.slideContent .block.blockContact .blockContent .blockPs a:hover, .slideContent .block.blockContact .blockContent .blockPs a:active {
    color: #014388;
}

.slideContent .block.blockContact .blockContent .separator {
    width: 45%;
    height: 1px;
    margin: 20px 0px 30px 88px;
    border-top: 1px solid #94979b;
}

.slideContent .spacer {
    clear: both;
}

.slideCollapse .slideSnackbar {
    margin-right: 25%;
}
.slideCollapse.big .slideSnackbar {
    margin-right: 13%;
}