.listDashbloard
{
  height: calc(100% - 189px);
  margin-left:20px;
  margin-right:20px;
  min-width:990px;
  position: relative;
}

.listDashbloard .MuiPaper-root,
.listDashbloard .MuiMasonry-root
{
  height:100%;
  margin-bottom: 0px;
  padding-bottom:0px;
}


.listDashbloard .MuiDataGrid-root
{
  border:none;
}
.listDashbloard .MuiDataGrid-footerContainer
{
  display:none;
}


.dashboardTuiles .MuiMasonry-root,
.dashboardTuiles .MuiPaper-root,
.dashboardTuiles .MuiDataGrid-columnHeaders  {
  background:none;
  box-shadow:none;
  border:none;
  margin:0px;
}

.dashboardTuiles .MuiPaper-root.columnTuiles {
  width: calc(25.00%);
  padding-left:15px;
  padding-right:15px;
}

.dashboardTuiles .rightBorder {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.dashboardTuiles .MuiDataGrid-cell {
  border-bottom:none!important;
  display:block;
}

.dashboardTuiles .MuiDataGrid-row {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 10px 0 rgba(0,0,0,0.1);
  margin-bottom:10px;
  display:block;
}
.dashboardTuiles .MuiDataGrid-row:last-child {
  margin-bottom: 0px;
}

.dashboardTuiles .MuiDataGrid-row:hover {
    background-color: #FFFFFF!important;
    box-shadow: 0px 0 2px 0 rgba(0,0,0,0.1);
  }

.dashboardTuiles .MuiDataGrid-columnSeparator {
  display:none!important;
}

.dashboardTuiles  .MuiDataGrid-cell--withRenderer {

}
.tuile {
  display: block;
  position: relative;
  width:100%;
}
.tuile .typeMissionColor {
  display: block;
  width: 4px;
  height: 43px;
  padding-right: 10px;
  left: -12px;
  top:10px;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tuile .titre {
  color: #000000;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px!important;
  margin-left:10px;
  margin-Top:14px;
}

.tuile .principales {
  color: #94979B;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  margin-left:10px;
}
.tuile .missionTypeLabel {
  margin-left:10px;
  margin-Top:14px;
  color: #000000;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
}

.tuile .created  {
  margin-left:10px;
  margin-Top:5px;
  color: #000000;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
}

.tuile .b_o_l {
  margin-left:10px;
  margin-Top:5px;
  color: #94979B;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
}

.tuile .b_o_l span {
  color: #000000;
}

.tuile .vessel {
  margin-left:10px;
  margin-Top:5px;
  color: #94979B;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
}

.tuile .vessel span {
  color: #000000;
}

.tuile .warehouse {
  margin-left:10px;
  margin-Top:5px;
  color: #94979B;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
}

.tuile .warehouse span {
  color: #000000;
}

.tuile .statusMissionGridColor {
  position: absolute;
  right:0px;
  top:10px;
}

.HeaderTitle {
  color:#000000;
  font-size: 16px;
  font-weight: 500;
}

.HeaderNumber {
  color:#94979B;
  margin-left:15px;
}
