.signaturePlaceDiv {
  border-radius: 0px !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom: none !important;
  margin: 0px 8px 0px 8px !important;
  /*box-shadow: 0px 2px 0px -1px rgba(0,0,0,0.2),0px 1px 0px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;*/
}
.blocSignaturePlaceDiv .signaturePlaceDiv:first-child{
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
.blocSignaturePlaceDiv .signaturePlaceDiv:last-child{
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.signaturePlaceAccordion{
    box-shadow: none !important;
    border: none !important;
}

.signaturePlaceRowAccordion{
  border-radius: 0px !important;
  box-shadow: none !important;
  margin: 0px 8px 0px 8px !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom: none !important;
}
.signaturePlaceRowAccordion:first-child{
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
.signaturePlaceRowAccordion:last-child{
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.blSignaturePlaceColumnDiv {
  display:inline-block;
  vertical-align:top;
}


.blDate{
  margin-bottom:8px !important;
  margin-left: 0px !important;
}

.unaffectedRowsDiv{
  margin: 20px 0px 0 8px !important;
}

.signaturePlaceRowAccordion .textField-form-date{
  margin-left: 0px;
  margin-bottom: 8px;
}
