html, body, #root {
  background-color: #024289;
}

.hidden, .unvisible {
  display: none;
}

.welcome-to-gyptis {
  width:100%;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin-top:60px;
  margin-bottom: 20px;
}

.Login-box {
  width: 90%;
  max-width: 560px;
  background-color: #FFFFFF;
  margin:auto;
  margin-bottom:100px;
  border-radius: 15px;
  padding-top:55px;
  padding-bottom:55px;
}

.Login-logo {
  height: 87px;
  width: 87px;
  margin:auto;
}

.Login-formulaire {
  width: 80%;
  max-width: 400px;
  margin:auto;
  margin-top:50px;
}

.Login-identifier, .Login-password {
  color: #343434;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.Login-identifier-input, .Login-password-input {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 1px solid #979797;
  opacity: 0.5;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 30px;
}

.Login-password-input {
  margin-bottom: 5px;
}

.Login-forgot-password a {
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.Login-forgot-password a, .Login-forgot-password a:default, .Login-forgot-password a:active, .Login-forgot-password a:hover {
  color: #343434;
}

.Login-rectangle-button {
  margin-top:20px;
  height: 50px;
  width: 100%;
  border-radius: 6px;
  background-color: #024289;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}

.errmsg {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
}

.hint_id_new_password {
  font-family: Poppins;
  color: #8e9497;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-left: 5px;
}

.Login-input-nomaginbottom {
    margin-bottom: 0px;
}

a.link, a.link:active, a.link:visited {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}
