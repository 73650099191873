.contentDetail {
  min-height: calc(100% - 170px);
  padding: 20px;
}

.contentDetail.block_details {
  margin-bottom: 30px;
}

.contentDetail.block_details:last-child {
  margin-bottom: 0px;
}

.contentDetail.block_details.editing {
  border: solid 1px #1976d2;
  background-color: color(srgb 0.099 0.464 0.825 / 0.05);
}

.contentDetail.block_details.editing>h4 {
  color: #1976d2;
}

.contentDetail.block_details.canceled {
  border: solid 1px red;
  background-color: rgba(255, 0, 0, 0.05);
}

.contentDetail.block_details.canceled>h4 {
  color: red;
}

.mission-titre {
  height: 36px;
  color: #000000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 39px;
}

.mission-principal {
  height: 16px;
  color: #94979B;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

div.textField-form div.MuiInputBase-formControl:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

div.lineContent {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}

div.lineContent.noJustify {
  justify-content: left;
}

div.lineContent.noJustify>div {
  margin-right: 1.89% !important;
}

div.lineContent.noJustify>div:last-child {
  margin-right: 0px !important;
}

div.lineContent.noJustify>div>div:not(.addButtonSelect1, .addButtonSelect2) {
  width: 100%;
}

div.lineContent.separator {
  width: calc(100% - 8px);
  border-top: 1px solid #CACACA;
  padding-top: 15px;
  margin-top: 30px;
  margin-left: 8px;
}

div.MuiFormControl-root {
  margin-bottom: 0px;
}

.block_details.editing .lineContent .MuiFormControl-root label {
  z-index: 2;
}

.block_details.editing .lineContent .MuiFormControl-root:not(:has(.MuiAutocomplete-root, .MuiTextField-root, .MuiInputBase-root, .addButtonSelect1, .addButtonSelect2, .MuiFormHelperText-root.Mui-error), .textField-form-detail),
.block_details.editing .lineContent .MuiFormControl-root .MuiInputBase-root {
  background-color: white;
}

.block_details.editing .lineContent .MuiFormControl-root .MuiFormControl-root {
  background-color: transparent;
}

div.MuiFormControl-root.MuiTextField-root,
#datagrid-files,
#datagrid-links,
#datagrid-notifications,
ul.MuiList-root,
#block_note .lineContent:not(.separator) .MuiFormControl-root.textField-form-comment {
  width: calc(100% - 8px);
  margin-right: 0px;
}

.lineContent .MuiFormControl-root {
  margin-right: 0px;
}

.lineContent.separator .MuiFormControl-root:first-child,
.lineContent .MuiFormControl-root.textField-form-comment {
  margin-left: 0px;
}

.lineContent.separator .MuiFormControl-root.MuiTextField-root:first-child,
.lineContent .MuiFormControl-root.textField-form-comment>div.MuiFormControl-root.MuiTextField-root,
.block_details.view .lineContent div.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

#block_general .lineContent div.MuiFormControl-root.textField-form-date>div.MuiFormControl-root.MuiTextField-root,
#block_general .lineContent div.MuiFormControl-root.textField-form>div.MuiFormControl-root.MuiTextField-root,
#block_receiver .lineContent div.MuiFormControl-root.textField-form-date>div.MuiFormControl-root.MuiTextField-root,
#block_receiver .lineContent div.MuiFormControl-root.textField-form>div.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

label.MuiFormControlLabel-root {
  padding-left: 8px;
}
label.MuiFormControlLabel-root:not(:has(input[type="checkbox"])) {
  padding-top: 10px;
  padding-left: 15px;
}
label.MuiFormControlLabel-root svg{
  margin-top: -2px;
}
label.MuiFormControlLabel-root svg.valid {
  color: #1976d2;
}
label.MuiFormControlLabel-root svg.noValid {
  color: #94979B;
}
label.MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}
label.MuiFormControlLabel-root:not(:has(input[type="checkbox"])) .MuiFormControlLabel-label {
  color: gray;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  padding-left: 10px;
}

.block_details .block_button {
  top: 20px;
  right: 20px;
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  z-index: 850;
  position: absolute;
  justify-content: space-between;
}

.block_details:hover .block_button,
.block_details.editing .block_button,
.block_details.canceled .block_button {
  opacity: 1;
  transition: all 1s;
}

.block_details .block_button button {
  margin: 0px 2.5px;
  font-size: 11px;
  padding: 1px 6px !important;
  border-radius: 10px;
  pointer-events: none !important;
  text-transform: uppercase;
  box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.1);
}
.block_details .block_button button:has(.MuiButton-endIcon) {
  padding: 1px 4px 0px 6px !important;
}
.block_details .block_button button:has(.MuiButton-startIcon) {
  padding: 1px 6px 0px 4px !important;
}

.block_details.editing #siForm .classic_button {
  height: 33px !important;
  margin-top: 15px !important;
  line-height: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.block_details .block_button>button, .block_details.editing .classic_button {
  color: #1976d2;
  background-color: #FFFFFF;
  border: 1px solid #1976d2;
  transition: all 1s;
}

.block_details:hover .block_button button {
  pointer-events: auto !important;
}

#block_protis.block_details.disabled *:not(.eventEnabled), .block_details .block_button button:disabled {
  pointer-events: none !important;
}

#block_protis.block_details.disabled *:not(.eventEnabled) button {
  opacity: 0;
  transition: all 0.5s;
}

#block_protis.block_details * button {
  opacity: 1;
  transition: all 1s;
}

.block_details .block_button button:disabled {
  opacity: 0.3;
  color: gray;
  transition: all 0.5s;
  border: 1px solid gray;
}

.block_details .block_button button.cancel {
  color: red;
  border: 1px solid red;
}

.block_details .block_button button.edit {
  padding: 3px 6px !important;
}

.block_details .block_button>button:hover:not(:disabled), .block_details.editing .classic_button:hover {
  color: white;
  background-color: #1976d2;
}

.block_details .block_button button.cancel:hover:not(:disabled) {
  background-color: red;
}

.block_details .block_button button svg {
  font-size: 12px;
}

.block_details .block_button button .MuiButton-startIcon,
.block_details .block_button button>svg {
  margin: -2px 2px 0px 0px;
}

.block_details .block_button button .MuiButton-endIcon {
  margin: -2px 0px 0px 4px !important;
}

.block_details .block_button button .MuiLoadingButton-loadingIndicator {
  left: 9px;
}

.block_details .block_button button.cancel .MuiLoadingButton-loadingIndicator {
  left: 6px;
}

.block_details .block_button button .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root {
  width: 10px !important;
  height: 10px !important;
  margin: -0.5px 2px 0px 0px;
}

.floatingSaveButton {
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 999;
}

.floatingSaveButton .cancel {
  color: white;
  background-color: red;
}

.floatingSaveButton .cancel:hover {
  background-color: #c90300;
}

.opacity {
  opacity: 0;
  transition: all 0.5s;
}

*:not(.floatingSaveButton).opacity * {
  pointer-events: none !important;
}

.opacity.show {
  opacity: 1;
  transition: all 1s;
}

*:not(.floatingSaveButton).opacity.show * {
  pointer-events: auto !important;
}

.floatingSaveButton.opacity button {
  pointer-events: none !important;
}

.floatingSaveButton.opacity.show button {
  pointer-events: auto !important;
}

div.content {
  overflow-y: scroll;
}

div.MuiFormControl-root.error-form label {
  color: #d32f2f;
}

div.MuiFormControl-root.error-form div.MuiInputBase-formControl:before {
  border-bottom: 1px solid #d32f2f;
}

#loadingGeneral {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
#loadingGeneral.top {
  top: 0px;
  left: 0px;
  bottom: auto;
}

.editButtion {
  position: absolute;
  top: 12px;
  right: 50px;
}

.textField-form-date {
  margin-top: 8px !important;
}

.detailFieldTitle {
  color: #94979B;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
}

.detailFieldContent {
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 21px;
  border-bottom: 0.5px solid #94979B;
}

.textField-form-date input {
  padding: 8.5px 14px;
}

.textField-form-date .MuiInputLabel-outlined {
  top: -5px;
}

.textField-form-date .MuiInputLabel-shrink {
  top: 0px;
}

.textField-form-date label {
  padding-right: 25px;
}

.customNoRowsOverlay {
  padding-top: 35px;
  text-align: center;
  height: 52px !important;
}

.addButtonSelect1 {
  position: absolute;
  top: -1px;
  right: -10px;
}

.addButtonSelect2 {
  position: absolute;
  top: 10px;
  right: -10px;
}

#datagrid-files .MuiDataGrid-footerContainer {
  display: none;
}

.lineContent .textFieldCustom fieldset {
  height: 45px;
  margin-top: 8px;
}

.iconAddButton .css-1d6wzja-MuiButton-startIcon {
  margin-top: -4.5px;
}

div.iconAddButton {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  padding: 6px 8px;
  color: #1976d2;
}

.loadingButtonBlock {
  text-align: center;
  margin-top: 38px !IMPORTANT;
  margin-bottom: 30px;
}

.loadingButtonCustom {
  opacity: 1;
  transition: all 0.5s;
  display: inline-block;
  padding-bottom: 3px !important;
}
.loadingButtonCustom.disabled {
  opacity: 0.05;
  transition: all 0.5s;
  pointer-events: none;
}

.loadingButtonCustom span.MuiButton-endIcon, .loadingButtonCustom span.MuiButton-startIcon {
  margin-top: -3px;
}

.Mui-error fieldset {
  border-color: #d32f2f !important;
}

.Mui-error label {
  color: #d32f2f !important;
}

.spacerBlDate.justRead {
  display: block;
  padding-bottom: 8px;
}

/*datepicker dataGrid*/
.MuiDataGrid-cell {
  position: relative;
}

.MuiDataGrid-cell .textField-form-date {
  width: auto;
  margin: 0px !important;
}

.MuiDataGrid-cell .textField-form-date label,
.MuiDataGrid-cell .textField-form label {
  display: none !important;
}

.MuiDataGrid-cell .textField-form-date input,
.MuiDataGrid-cell .textField-form input {
  width: auto;
  font-size: 15px;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textRight .textField-form input {
  text-align: right !important;
}

.MuiDataGrid-cell .textField-form-date .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.MuiDataGrid-cell .textField-form-date .MuiFormControl-root,
.MuiDataGrid-cell .textField-form-date .MuiInputBase-formControl {
  width: auto;
}

.MuiDataGrid-cell .textField-form .MuiFormControl-root,
.MuiDataGrid-cell .textField-form .MuiFormControl-root .MuiInput-root {
  margin-top: 0px;
}

.MuiDataGrid-cell div.textField-form div.MuiInputBase-formControl:before,
.MuiDataGrid-cell div.textField-form div.MuiInputBase-formControl:after {
  border-bottom: none !important;
}

/*selectcustom dataGrid*/
.MuiDataGrid-cell .MuiFormControl-root {
  margin-top: 5.5px;
  margin-bottom: 0px;
  width: 100%;
}

.MuiDataGrid-cell .MuiFormControl-root .MuiSelect-select {
  font-size: 12px;
  padding: 0px 15px 1px 7px !important;
}

.MuiDataGrid-cell .MuiFormControl-root .MuiSelect-icon {
  right: 0px;
}

.MuiDataGrid-cell .MuiFormControl-root .MuiOutlinedInput-notchedOutline legend {
  display: none;
}

#datagrid-files .toolbarButton .css-1d6wzja-MuiButton-startIcon {
  margin-top: -4px;
}

/*datagrid toolbar*/
.DataGridToolbar {
  height: 36px;
}

.DataGridToolbar .toolbarButton {
  top: 0px;
  position: absolute;
}

.DataGridToolbar .toolbarButton.left {
  left: 0px;
}

.DataGridToolbar .toolbarButton.right {
  right: 0px;
}

.float {
  float: left;
}

.float.right {
  float: right;
}

.spacer {
  clear: both;
}

.noMargeTop,
.fullNoMargeTop,
.fullNoMargeTop * {
  margin-top: 0px !important;
}

.noMargeBottom,
.fullNoMargeBottom,
.fullNoMargeBottom * {
  margin-bottom: 0px !important;
}

.noMargeLeft,
.fullNoMargeLeft,
.fullNoMargeLeft * {
  margin-left: 0px !important;
}

.LoadingButtonIcon {
  margin-top: -3px;
}

.MuiDataGrid-main .MuiAutocomplete-root.upd {
  width: 97%;
}

.MuiDataGrid-main .addButtonSelect1 {
  top: -5px;
  right: 35px;
}
.MuiDataGrid-main .addButtonSelect1.upd {
  right: 0px;
}
.MuiDataGrid-main .addButtonSelect1.autocomplete {
  top: -6px;
  right: -3px;
}