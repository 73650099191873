.mission-documents{
   background-color: white !important;
   position:fixed;
   min-height: 100%; 
   min-width: 100%; 
}

.mission-documents-logo{  
    height: 87px;
    width: 87px;
    margin:auto;
    margin-top:50px;
}

@media all and (min-width: 1000px){
    .mission-documents-container{
        margin-left:20%;
        margin-right:20%;
    }
}


@media all and (max-width: 999px){
    .mission-documents-container{
        margin-left:10%;
        margin-right:10%;
    }
}

@media all and (max-width: 800px){
    .mission-documents-container{
        margin-left:0%;
        margin-right:0%;
    }
}

@media all and (max-width: 599px){
    .mission-documents-cell{
        width:100%;
        padding-top:0;
        padding-bottom:10px;
    }
}

@media all and (min-width: 600px){
    .mission-documents-cell{
        width:50%;
        padding:15px;
    }
}

.mission-documents-recap, .mission-documents-files{
    background-color: rgb(239, 239, 239);
    padding:15px;
    font-weight:bold;
}

.mission-documents-recap{
    margin-top :40px;
}

.mission-documents-files{
    margin-top :15px;
} 

.mission-documents-cell{
    display:inline-block;
    vertical-align: top;
}

.mission-documents-file{
    position:relative;
    padding:10px;
    background-color: white;
    border-radius: 3px;
    height:100%;
    min-height:90px;
}

.gray-text{
    color:rgb(62, 62, 62);
}

.light-gray-text{
    color:rgb(147, 147, 147);
}

.mission-documents-download{
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
}

.mission-documents-download-button{
    width:100%;
}

.ReCaptcha-modal{
    background-color: white;
    padding : 20px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}