@font-face {
  font-family: 'Poppins';
    src:  url('..\/font\/Poppins.ttf.woff') format('woff'),
    url('..\/font\/Poppins.ttf.svg#Poppins') format('svg'),
    url('..\/font\/Poppins.ttf.eot'),
    url('..\/font\/Poppins.ttf.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

<link
  rel="stylesheet"
  href="https:\/\/fonts.googleapis.com\/css?family=Roboto:300,400,500,700&display=swap"
\/>

<link
  rel="stylesheet"
  href="https:\/\/fonts.googleapis.com\/icon?family=Material+Icons"
\/>

body {
  font-family: Poppins;
  margin: 0;
}
