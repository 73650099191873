.contentList, .contentFilter, .contentDetail, .contentHeader, .contentListMailing {
    margin:20px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 1px 0 10px 0 rgba(0,0,0,0.1);
    min-width: 990px;
    position: relative;
}
  
.contentListMailing {
    background-color: white;
    height: calc(100% - 150px);
}
  
.resetButtonFilterMailing {
    position:absolute;
    top:2px;
    right:36px;
    color: #94979B;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}

.resetButtonFilterMailing i {
    font-size: 20px;
}
  
.resetButtonFilterMailing span {
    margin-left:5px;
    position: relative;
    top:-3px;
}
  
.resetButtonFilterMailing:hover{
    cursor: pointer;
}
  
.resetButtonFilterMailing.hide {
    display:none;
}