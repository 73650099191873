.chartDashboardContainer {
  margin: 15px;
  padding: 10px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.1);
  width: calc(100% - 31px);
  height: calc(100% - 31px);
}

.chartDashboardContainer .chartToolbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  height: calc(15% - 5px);
}

.chartDashboardContainer .chartToolbarContainer > *:first-child {
  margin-right: auto;
}


.chartDashboardContainer .chartToolbarContainer > *:nth-child(2) {
  margin-left: auto;
  margin-right: auto;
}

.chartDashboardContainer .chartToolbarContainer > *:not(:first-child):not(:nth-child(2)) {
  margin: 0 10px; /* Adjust the margin between elements */
}

.chartDashboardContainer .chartToolbarContainer .chartTypeToggleButtonGroup {
  display: flex;
  height: 1rem;
  align-items: center;
}

.chartDashboardContainer .chartToolbarContainer .chartTypeToggleButtonGroup .chartTypeToggleButton {
  height: 100%;
}

.chartDashboardContainer .chartToolbarContainer .chartDateRangePicker {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  z-index: 999;
}

.chartDashboardContainer .chartLineContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: calc(100% - 20px);
}

@media screen and (min-width: 1600px) {
  .chartDashboardContainer .chartLineContainer {
    padding-bottom: 20px;
  }
}

.chartDashboardContainer .chartPieContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: calc(100% - 20px);
}

@media screen and (min-width: 1600px) {
  .chartDashboardContainer .chartPieContainer {
    padding-bottom: 20px;
  }
}

.chartDashboardContainer .chartTableContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: calc(100% - 20px);
}

.chartDashboardContainer .chartTableContainerWithToolbar {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: calc(85% - 20px);
}

.chartDashboardContainer .chartTableContainerWithToolbar .chartTableToolbar {
  height: 15%;
}

.chartDashboardContainer .chartTableContainerWithToolbar .chartTableTypeToggleButtonGroup {
  height: 1%;
  width: 100%;
}

.chartDashboardContainer .chartTableContainerWithToolbar .chartTableTypeToggleButtonGroup .chartTableTypeToggleButton {
  width: 100%;
}

.chartTable {
  height: 100%;
}

.chartTableHead {
  top: 0%;
}

.chartTableBody {
  height: 70%;
  overflow: auto;
}

.chartTableBody .chartTableComponent {
  table-layout: fixed;
}

.chartTableBody .chartTableComponent .MuiTableCell-root {
  border-bottom: none;
}

.loadingChartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lineInfoContainer {
  display: flex;
  height: 50%;
}
