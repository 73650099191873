.FULL-CONTENT {
  width:100%;
  height:100%;
  position:fixed;
}

.PORTAL, .DASHBOARD {
  background-color: #F4F7FA;
}

.content {
  position:fixed;
  padding-left: 200px;
  width:100%;
  height:100%;
}

.header-menu {
  margin-top:25px;
  margin-left:20px;
  position:relative;
}

.titre-logo {
  position:absolute;
  top:7px;
  left:65px;
  color: #024289;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 33px;
}

.user-menu {
  width: 100%;
  font-size: 8px;
  color: #94979b;
  text-align: center;
}

.content-menu {
  margin-top: 45px;
  overflow-y: auto;
}

.content-menu::-webkit-scrollbar {
  width: 6px; 
}

.content-menu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.content-menu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 6px;
}

.menu-item {
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  margin-top: 3px;
  height: 35px;
  border-left: solid white 5px;
  padding-top: 8px;
}

.titre-item-menu {
  color: #94979B;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  position:absolute;
  top:11px;
  left:35px;
  padding-left:20px;
}

.menu-item-end {
  width: 170px;
  height: 1px;
  background-color: #DBDFE9;
  margin:auto;
  margin-top: 15px;
}

.menu-item.active, .menu-item:hover {
  border-left: solid #024289 5px;
}

.menu-item.active .titre-item-menu {
  font-weight: 500;
  color: #000000;
}

.menu-item:hover .titre-item-menu {
  color: #000000;
}

.puceNotifBorder{
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  border-radius: 16px;
  border: 2px solid white;
}
.puceNotifBorder .puceNotif{
  top: 0px;
  right: 0px;
  position: absolute;
  margin: 0px !important;
  color: white !important;
  border-radius: 6px;
  border: 6px solid #F90103;
  background-color: #F90103 !important;
}
