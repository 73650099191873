#menu {
  background-color: white;
  box-shadow: 1px 0 10px 0 rgba(0,0,0,0.1);
  height:100%;
  width: 200px;
  z-index: 901;
  position:fixed;
  min-height: 425px;
}

.nav-item {
  padding-left: 15px;
  padding-bottom: 15px;
}

.profile-nav-item {
  position: absolute;
  bottom: 0px;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
}

.nav-item .nav-link {
  padding: 0;
  color: #525f80;
  font-weight: 500;
  font-family: Poppins;
}

.profile-nav-item .menu-profile .avatar {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-right: 0;
  margin-left: 5px;
}

.profile-nav-item .menu-profile .name {
  color: #1B253A;
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 13px;
  font-family: Poppins;
  margin-left:10px;
}

.dropdown-menu-profile {
  padding: 10px 0px 10px 0px;
  bottom: 50px;
  left : -10px;
  -webkit-box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
        box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  background-color: #ffffff;
  border-color: #eeeeee;
  max-width: 280px;
  border-radius: 2px;
  border: none;
  -webkit-transition: .3s;
  transition: .3s;
  visibility: hidden;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: absolute;
}

.dropdown-menu-profile  .dropdown-header {
  border-radius: 5px 5px 0 0;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
  font-family: Poppins;
}

.dropdown-menu-profile  .dropdown-header .figure img {
  width: 80px;
  height: 80px;
}

.dropdown-menu-profile  .dropdown-header .name {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}

.dropdown-menu-profile  .dropdown-header .email {
  color: #686868;
  font-size: 12px;
}

.dropdown-menu-profile  .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}

.dropdown-menu-profile  .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-menu-profile  .dropdown-footer {
  margin: 0;
  padding: 10px 15px 0;
  border-top: 1px solid #eeeeee;
}

.dropdown-menu-profile  .dropdown-footer ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.dropdown-menu-profile  .dropdown-footer li {
  margin-bottom:0px;
}

.dropdown-menu-profile  .dropdown-footer .nav-item .nav-link {
  color: red;
  font-size: 14px;
  font-weight: 600;
}


.dropdown-menu-profile.show {
  visibility: visible;
  display: block;
  opacity: 1;
}

.dropdown-menu-profile.hide {
  visibility: hidden;
  display: none;
  opacity: 0;
}
