.header_canceled {
  top: 0px;
  left: calc(50% - 215px);
  z-index: 1000;
  position: fixed;
}

.header_canceled .canceled_title {
  z-index: 1;
  width: 430px;
  color: white;
  font-size: 22px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  background-color: red;
  padding: 3.5px 0px 3.5px 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.3);
}

.filterStatusDisabled * {
  pointer-events: none !important;
}

.contentHeader {
  height: 113px;
  padding: 10px 25px;
}

.contentHeader .FilterStatusContent {
  border-left: none;
  position: relative;
  top: -10px;
  left: -13px;
}

.pinned {
  position: absolute;
  top: 12px;
  right: 12px;
}

.reminderDate {
  position: absolute;
  top: 10px;
  right: 40px;
}

.reminderDate.detail {
  top: 13px;
  right: 130px;
}

.reminderDate label,
.reminderDate fieldset {
  display: none;
}

.reminderDate input {
  margin: 0px;
  padding: 0px;
  height: 0px;
}

.reminderDateSelector {
  top: 3px;
  right: 6px;
}

.reminderDate .picker button {
  display: none;
}

.reminderDate .picker {
  position: fixed;
  top: 20px;
  right: 90px;
}

.datesHeader.datesHeaderTitle {
  position: absolute;
  bottom: 10px;
  right: 100px;
}

.datesHeader.datesHeaderContent {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.datesHeader .titleDate {
  color: #94979B;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
}

.datesHeader .contentDate {
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
}

.puceButtonNotif {
  top: -2px;
  right: -5px;
  position: absolute;
}

.puceButtonNotif .puceNotif {
  width: 16px;
  height: 16px;
  margin: 0px !important;
  color: white !important;
  border-radius: 17px;
  border: 17px solid #F90103;
  background-color: #F90103 !important;
}

.puceButtonNotif .puceNotif .MuiBadge-badge.BaseBadge-badge {
  font-size: 16px !important;
}

.missionTypeDetail {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 8px;
  border-radius: 6px 0px 0px 6px;
}

.missionTypeDetail.sticky {
  border-top-left-radius: 0px;
}

.floatingHeaderMission {
  width: 100%;
  height: 80px;
  position: fixed;
  top: -80px;
  left: 0px;
  z-index: 999;
  padding-left: 200px;
  transition: top 1s;
}

.floatingHeaderMission.visible {
  top: 0px;
}

.floatingHeaderMission .contentHeader {
  height: 77px;
  margin-top: 0px;

  border-radius: 0px 0px 6px 6px;
}

.floatingHeaderMission .FilterStatusContent {
  border-left: none;
}

.floatingHeaderMission .filterStatusSticky {
  position: absolute;
  top: 25px;
  right: 0px;
}

.floatingHeaderMission .reminderDate {
  top: 3px;
}

.floatingHeaderMission .pinned {
  top: 5px;
}

.editButtion.sticky {
  top: 6px;
}

.editButtion.sticky button {
  height: 25px;
}

.floatingHeaderMission .reminderDate.sticky {
  top: 6.5px;
}

.datagridLot,
.datagridBol {
  height: 100%;
}

.datagridLot .css-1bn7npi-MuiDataGrid-root,
.datagridLot .css-f3jnds-MuiDataGrid-columnHeaders,
.datagridBol .css-1bn7npi-MuiDataGrid-root,
.datagridBol .css-f3jnds-MuiDataGrid-columnHeaders {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}

.buttonMarkAsRead {
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 1px 12px 0px 12px !important;
  background-color: #6cad7c !important;
}

.exportCRDiv {
  width: calc(100% - 8px);
  margin: 8px 0px 0px 8px;
  position: relative;
}

.exportCRDiv * {
  cursor: default !important;
}

.exportCRDiv button {
  cursor: pointer !important;
}

.exportCRDiv .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

div.lineContentBL {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 0.2em;
  padding-right: 2%;
}

.block_details .block_button .workflowSaveButton {
  margin-right: 20px;
}

.block_details .block_button .workflowSaveButton .loadingButtonBlock {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.block_details .block_button .workflowSaveButton button {
  padding: 3.5px 9.5px !important;
}

.block_details .block_button .workflowSaveButton button .MuiLoadingButton-loadingIndicator {
  left: auto;
  right: 9px;
}

#buttonAddBOL {
  position: absolute;
  top: 10px;
  right: 10px;
}

#datagrid-bol div.MuiDataGrid-footerContainer,
#datagrid-receivers div.MuiDataGrid-footerContainer {
  display: none;
}

.subInvoiceFilenameSpan {
  white-space: nowrap;
  width: 100%;
  /* IE6 needs any width */
  overflow: hidden;
  /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis;
  /* Opera < 11*/
  text-overflow: ellipsis;
  /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}

/* import CR */
#blockImportCR {
  position: relative;
}

#blockImportCR .block_button {
  right: 0px;
  opacity: 1;
  transition: all 1s;
}

#blockImportCR .datagridIconLegend {
  top: 23px;
  right: 170px;
  color: gray;
  font-size: 10px;
  position: absolute;
}

#blockImportCR .datagridIconLegend span {
  padding-right: 5px;
}

.datagridImportCR {
  margin: 1px;
  width: 100%;
  height: 267px;
}

.datagridImportCR .MuiDataGrid-pinnedColumnHeaders {
  background-color: #f8f8f8 !important;
}

.datagridImportCR .MuiDataGrid-pinnedColumns .MuiDataGrid-row .MuiDataGrid-cell--editing,
.datagridImportCR .MuiDataGrid-pinnedColumns div[data-field="xls"] {
  background-color: transparent !important;
  box-shadow: 2px 0px 4px -2px rgba(0, 0, 0, 0.21);
}

.datagridImportCR .dataGridRowMissionCanceled.MuiDataGrid-row:hover,
.datagridImportCR .dataGridRowMissionCanceled.MuiDataGrid-row.Mui-hovered {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.datagridImportCR .importDataStateCustom {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 20px;
}

.datagridImportCR .importDataStateCustom.error {
  padding-top: 25px;
  padding-bottom: 0px;
}

.datagridImportCR .importDataStateCustom span {
  padding-left: 2px;
  padding-right: 2px;
}

.datagridImportCR .importDataStateCustom span * {
  margin-top: -2.5px;
}

.datagridImportCR .importDataStateCustom .selectCustom {
  width: calc(99% - 28px) !important;
}

.datagridImportCR .importDataStateCustom .MuiFormHelperText-root {
  margin-top: 0px !important;
  margin-left: 2px !important;
}