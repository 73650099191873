.dataGrid .addButtonSelect2 {
  top: -10px;
  right: -5px;
}
.dataGrid .addButtonSelect2.autocomplete {
  top: -6px;
  right: -3px;
}

.dataGridRenderCellSkeleton {
  width: 100%;
  height: 26px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(198, 26, 36);
}