.boxPopin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #ffffff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  padding: 16px;
}

.modalPopin .headerPopin {
  font-weight: 500;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  color: #000000;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #CCC;
}

.modalPopin.error .headerPopin {
  color: #d32f2f;
  border-color: #d32f2f;
}

.modalPopin.workflow .headerPopin {
  color: #9c27b0;
  border-color: #9c27b0;
}

.modalPopin .headerPopin .closePopin {
  top: 8px;
  right: 8px;
  position: absolute;
}

.modalPopin .contentPopin {
  margin-bottom: 20px;
}

.modalPopin.file .contentPopin,
.modalPopin.info .contentPopin {
  margin-top: 20px;
}

.modalPopin .contentPopin div.info_content {
  padding: 10px 0px 10px 0px;
}
.modalPopin .contentPopin ul li.withIcon {
  list-style: none;
}
.modalPopin .contentPopin a, .modalPopin .contentPopin a:link, .modalPopin .contentPopin a:visited, .modalPopin .contentPopin a:hover, .modalPopin .contentPopin a:active {
  color: black;
  text-decoration: none;
}

/* specific company form */
.modalPopin.companyFormClass .modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 710px;
  background-color: white;
  height: 82%;
  box-shadow: 24;
  overflow: auto;
}

.modalPopin.companyFormClass .modalBox .headerPopin {
  font-weight: 500;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  color: #000000;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #CCC;
  position: sticky;
  z-index: 1;
  top: 0px;
  background: white;
  padding: 8px;
}

.modalPopin.companyFormClass .modalBox .footerPopin {
  position: sticky;
  z-index: 1;
  bottom: 0px;
  background: white;
  border-top: 0.5px solid #CCC;
}