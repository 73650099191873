.hideOnEditMode {
  opacity: 0.4;
  pointer-events: none;
}

.accordionPdfModal, .photoPdfModal, .containerPdfModal{
  color : lightgray !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.containerPdfModal{
  padding: 0px 10px 0px 10px;
}

.containerSeparator{
  background-color: lightgrey;
  height: 1px;
  width: 70%;
  margin: 7px 15% 7px 15%;
  opacity: 0.4;
}

.photoPdfModal{
  text-align: center;
  position:relative;
  display: flex;
  align-items: center;
}

.photoPdfModalColumnDiv{
  display:inline-block;
  padding:5px;
  text-align: left;
}

.photoPdfModalColumnDiv div{
  line-height: 1;
}

.folderCommentShort{ 
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.photoPdfModalComment, .folderCommentShort{ 
  margin-top: 3px;
  font-size: 12px;
}

.photoPdfModalIcon{
  position:absolute;
  top: 15px;
  left: 15px;
  opacity:0;
  z-index:10;
  cursor:pointer;
}

.photoPdfArrowIcon{
  width: 10px;
  height: 10px;
}

.photoPdfModalIcon:hover, .photoPdfModalColumnDiv:hover .photoPdfModalIcon{
  opacity:0.7;
}

.selectedPhotoDiv{
  opacity:0.4;
  pointer-events: none;
}

.protisPhotoDiv{
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoPdf{
  width: 60px;
  height: 60px;
  cursor: pointer; 
}

.pdfModalCheckboxDiv span{
  color: white !important;
}
.pdfModalCheckboxDiv label{
  width:225px;
}

.pdfModalCheckboxDiv{
  max-height: 25px;
}

.gridContext{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.gridItem {
  padding: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.gridItemContent {
  height: 200px;
  padding: 5px;
  border: 1px solid rgba(211, 211, 211, 0.5);
  border-radius: 2%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridItemContentImg{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain !important;
}

.customTreeItem .Mui-selected {
  background-color: rgb(2 136 209 / 30%) !important;
}