.multiModificationFooter {
    width: 100%;
    height: 56px;
    border-radius: 1px;
    margin-top: 10px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
}

.multiModificationFooter .skeletonContainer {
    height: 100%;
    width: 100%;
    border-radius: 1px;
}

.multiModificationFooter .skeletonContainer .skeleton {
    background-color: #E0E0E0;
    height: 100%;
    width: 100%;
    border-radius: 1px;
}

.multiModificationFooter .buttonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 8px;
}
  
.multiModificationFooter .buttonContainer .cancelIconButton {
    margin-right: 3px;
}
  
.multiModificationFooter .buttonContainer  .editTypeIconButton {
    margin-left: 8px;
    margin-right: 5px;
}

.multiModificationFooter .buttonContainer  .editRightsIconButton {
    margin-right: 5px;
}